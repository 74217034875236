import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'
import ContactForm from './ContactForm'

import bubbles from '../../../content/images/bubbles.svg'

const ReservationsPage = ({ title, description }) => (
  <S.Wrapper>
    <S.TopWrapper>
      <S.Title>{title}</S.Title>
      <S.Bubbles src={bubbles} alt="Contacts" />
    </S.TopWrapper>
    <S.Row>
      <S.Description dangerouslySetInnerHTML={{ __html: description }} />
    </S.Row>
    <S.Row>
      <S.MainCol>
        <ContactForm />
      </S.MainCol>
    </S.Row>
  </S.Wrapper>
)

ReservationsPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
export default ReservationsPage
