import styled from '@emotion/styled'

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 406px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
`
export const Image = styled.div`
  background-image: url(${({ src }) => src});
  position: relative;
  border-radius: 4px;
  background-position: ${({ isBengalPage }) => (isBengalPage ? 'center 20px' : 'center')};
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 1200px;

  opacity: ${({ isBengalPage }) => (isBengalPage ? 0 : 0.17)};
  background: ${({ theme }) => theme.colors.black};

  z-index: 100;
  pointer-events: none;
`

export const Title = styled.div`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: ${({ isBengalPage }) => (isBengalPage ? '80%' : '50%')};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 210;
  color: ${({ theme }) => theme.colors.bg};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 2rem;
    width: 100%;
  }
`
