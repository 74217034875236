import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroWithImage from '../components/shared/HeroWithImage'
import ReservationsPage from '../components/ReservationsPage'
import i18n from '../../config/i18n'

const Reservation = ({ data: { reservation }, pageContext: { locale }, location }) => {
  const {
    data: {
      hero_title: heroTitle,
      hero_image: { url: heroUrlImage },
      top_section_black_title: title,
      top_section_text: { html },
    },
  } = reservation

  return (
    <>
      <SEO
        pathname={location.pathname}
        locale={locale}
        banner={heroUrlImage}
        title={i18n[locale].seo.reservation.title}
      />
      <HeroWithImage src={heroUrlImage} title={heroTitle} />
      <ReservationsPage title={title} description={html} />
    </>
  )
}

export default Reservation

Reservation.propTypes = {
  data: PropTypes.shape({
    reservation: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query reservationQuery($locale: String!) {
    reservation: prismicReservationsPage(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        top_section_black_title
        top_section_text {
          html
        }
      }
    }
  }
`
