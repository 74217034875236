import * as React from 'react'
import PropTypes from 'prop-types'

export default class RenderParallax extends React.Component {
  state = { offSet: 0 }

  lastScrollY = 0

  ticking = false

  wrapper = React.createRef()

  currentBounding = null

  bodyBounding = null

  componentDidMount() {
    this.currentBounding = this.wrapper.current.getBoundingClientRect()
    this.bodyBounding = document.body.getBoundingClientRect()

    window.addEventListener('scroll', this.requestAnimation)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.requestAnimation)
  }

  requestAnimation = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(this.animation)
      this.lastScrollY = window.scrollY
      this.ticking = true
    }
  }

  isDesktop = () => window.innerWidth >= 900

  animation = () => {
    if (!this.isDesktop()) {
      return this.setState({ offSet: 0 })
    }

    const { force, offsetComp } = this.props
    const elementOffset = this.currentBounding.top - this.bodyBounding.top
    const threshold = 10 / force || 1
    const offSet = (this.lastScrollY - elementOffset * 0.9) / threshold + (offsetComp || 0)

    this.setState({ offSet })
    this.ticking = false
  }

  render() {
    const { children } = this.props
    const { offSet } = this.state

    return (
      <div style={{ width: '100%', height: '100%' }} ref={this.wrapper}>
        {children(offSet)}
      </div>
    )
  }
}

RenderParallax.propTypes = {
  force: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  offsetComp: PropTypes.number.isRequired,
}
