import React from 'react'
import PropTypes from 'prop-types'

import Parallax from '../../UI/Parallax'

import * as S from './styled'

const HeroWithImage = ({ isPost, isBengalPage, src, title }) => (
  <S.Wrapper>
    <S.InnerWrapper>
      <Parallax force={2.2} offsetComp={0}>
        {offSet => (
          <S.Image
            isBengalPage={isBengalPage}
            src={src}
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
          >
            {!isPost && <S.Overlay isBengalPage={isBengalPage} />}
            {title && <S.Title isBengalPage={isBengalPage}>{title}</S.Title>}
          </S.Image>
        )}
      </Parallax>
    </S.InnerWrapper>
  </S.Wrapper>
)

HeroWithImage.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  isBengalPage: PropTypes.bool,
  isPost: PropTypes.bool,
}

HeroWithImage.defaultProps = {
  isBengalPage: false,
  isPost: false,
  title: null,
}

export default HeroWithImage
