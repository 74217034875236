import React from 'react'
import { Formik, Form } from 'formik'
import { navigateTo } from 'gatsby-link'
import PropTypes from 'prop-types'

import TextInput from '../UI/Inputs/TextInput'
import EmailInput from '../UI/Inputs/EmailInput'
import TelInput from '../UI/Inputs/TelInput'
import { LocaleContext } from '../Layout'

import * as S from './styled'

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const ContactForm = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        street: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        occupation: '',
        reference: '',
        kitten: '',
        interest: '',
        house: '',
        personResponsible: '',
        otherAnimals: '',
        veterinary: '',
      }}
      validate={values => {
        const errors = {}
        const { required, emailInvalid } = i18n.formReservation.errors
        if (!values.email) {
          errors.email = required
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = emailInvalid
        }
        if (!values.firstName) {
          errors.firstName = required
        }
        if (!values.lastName) {
          errors.lastName = required
        }
        if (!values.contact) {
          errors.contact = required
        }
        if (!values.street) {
          errors.street = required
        }
        if (!values.doorNumber) {
          errors.doorNumber = required
        }
        if (!values.postalCode) {
          errors.postalCode = required
        }
        if (!values.city) {
          errors.city = required
        }
        if (!values.occupation) {
          errors.occupation = required
        }
        if (!values.reference) {
          errors.reference = required
        }
        if (!values.kitten) {
          errors.kitten = required
        }
        if (!values.interest) {
          errors.interest = required
        }
        if (!values.house) {
          errors.house = required
        }
        if (!values.personResponsible) {
          errors.personResponsible = required
        }
        if (!values.otherAnimals) {
          errors.otherAnimals = required
        }
        if (!values.veterinary) {
          errors.veterinary = required
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'reservation', ...values }),
        })
          .then(() => navigateTo('/success'))
          .catch(() => (isPt ? navigateTo('error') : navigateTo('en/error')))

        setSubmitting(false)
      }}
    >
      {({ isSubmitting, errors, ...props }) => (
        <Form name="reservation" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <S.FormWrapper>
            <S.FormRow>
              <input type="hidden" name="form-name" value="reservation" />
              <S.FormCol>
                <TextInput
                  label={`${i18n.formReservation.firstName.label}*`}
                  placeholder={i18n.formReservation.firstName.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.firstName}
                  name="firstName"
                  errors={!!(errors.firstName && errors.firstName.length !== 0)}
                  errorMessage={errors.firstName}
                />
              </S.FormCol>
              <S.FormCol>
                <TextInput
                  label={`${i18n.formReservation.lastName.label}*`}
                  placeholder={i18n.formReservation.lastName.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.lastName}
                  name="lastName"
                  errors={!!(errors.lastName && errors.lastName.length !== 0)}
                  errorMessage={errors.lastName}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow>
              <S.FormCol>
                <EmailInput
                  label={`${i18n.formReservation.email.label}*`}
                  placeholder={i18n.formReservation.email.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  name="email"
                  errors={!!(errors.email && errors.email.length !== 0)}
                  errorMessage={errors.email}
                />
              </S.FormCol>
              <S.FormCol>
                <TelInput
                  label={`${i18n.formReservation.contact.label}*`}
                  placeholder={i18n.formReservation.contact.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.contact}
                  name="contact"
                  errors={!!(errors.contact && errors.contact.length !== 0)}
                  errorMessage={errors.contact}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow noPadding>
              <S.FormCol>
                <TextInput
                  label={`${i18n.formReservation.street.label}*`}
                  placeholder={i18n.formReservation.street.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.street}
                  name="street"
                  errors={!!(errors.street && errors.street.length !== 0)}
                  errorMessage={errors.street}
                />
              </S.FormCol>
              <S.FormCol>
                <TextInput
                  label={`\xA0`}
                  placeholder={i18n.formReservation.doorNumber.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.doorNumber}
                  name="doorNumber"
                  errors={!!(errors.doorNumber && errors.doorNumber.length !== 0)}
                  errorMessage={errors.doorNumber}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow>
              <S.FormCol>
                <TextInput
                  label={`\xA0`}
                  placeholder={i18n.formReservation.city.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.city}
                  name="city"
                  errors={!!(errors.city && errors.city.length !== 0)}
                  errorMessage={errors.city}
                />
              </S.FormCol>
              <S.FormCol>
                <TextInput
                  label={`\xA0`}
                  placeholder={i18n.formReservation.postalCode.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.postalCode}
                  name="postalCode"
                  errors={!!(errors.postalCode && errors.postalCode.length !== 0)}
                  errorMessage={errors.postalCode}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow>
              <S.FormCol>
                <TextInput
                  label={`${i18n.formReservation.occupation.label}*`}
                  placeholder={i18n.formReservation.occupation.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.occupation}
                  name="occupation"
                  errors={!!(errors.occupation && errors.occupation.length !== 0)}
                  errorMessage={errors.reference}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.reference.label}*`}
                placeholder={i18n.formReservation.reference.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.reference}
                name="reference"
                errors={!!(errors.reference && errors.reference.length !== 0)}
                errorMessage={errors.reference}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.kitten.label}*`}
                placeholder={i18n.formReservation.kitten.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.kitten}
                name="kitten"
                errors={!!(errors.kitten && errors.kitten.length !== 0)}
                errorMessage={errors.kitten}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.interest.label}*`}
                placeholder={i18n.formReservation.interest.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.interest}
                name="interest"
                errors={!!(errors.interest && errors.interest.length !== 0)}
                errorMessage={errors.interest}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.house.label}*`}
                placeholder={i18n.formReservation.house.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.house}
                name="house"
                errors={!!(errors.house && errors.house.length !== 0)}
                errorMessage={errors.house}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.personResponsible.label}*`}
                placeholder={i18n.formReservation.personResponsible.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.personResponsible}
                name="personResponsible"
                errors={!!(errors.personResponsible && errors.personResponsible.length !== 0)}
                errorMessage={errors.personResponsible}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.otherAnimals.label}*`}
                placeholder={i18n.formReservation.otherAnimals.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.otherAnimals}
                name="otherAnimals"
                errors={!!(errors.otherAnimals && errors.otherAnimals.length !== 0)}
                errorMessage={errors.otherAnimals}
              />
            </S.FormRow>
            <S.FormRow>
              <TextInput
                label={`${i18n.formReservation.veterinary.label}*`}
                placeholder={i18n.formReservation.veterinary.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.veterinary}
                name="veterinary"
                errors={!!(errors.veterinary && errors.veterinary.length !== 0)}
                errorMessage={errors.veterinary}
              />
            </S.FormRow>
            <S.Button type="submit" disabled={isSubmitting}>
              {i18n.formReservation.submit}
            </S.Button>
          </S.FormWrapper>
        </Form>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    street: PropTypes.string,
    doorNumber: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    occupation: PropTypes.string,
    reference: PropTypes.string,
    kitten: PropTypes.string,
    interest: PropTypes.string,
    house: PropTypes.string,
    personResponsible: PropTypes.string,
    otherAnimals: PropTypes.string,
    veterinary: PropTypes.string,
  }),
}

ContactForm.defaultProps = {
  handleChange: () => {},
  handleBlur: () => {},
  values: {},
}
export default ContactForm
